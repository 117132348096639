.banner {
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  position: relative;
  z-index: 1;

  &__bg {
    @include img("bg");
  }

  &__title {
    font-size: 47px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
    position: relative;
    color: $white;
  }

  &__breadcrumb {
    display: flex;
    justify-content: center;
    text-align: center;

    .breadcrumb {
      justify-content: center;
    }
  }

  @media (max-width: 767px) {
    height: 200px;

    &__title {
      font-size: 24px;
    }
  }
}

.frame {
  &.-circle {
    @include frame("circle");
  }

  &.-square {
    @include frame("square");
  }

  &.-scale-16by9 {
    @include frame("rect", 56.25%);
  }

  &.-scale-1by1 {
    @include frame("rect", 100%);
  }

  &.-scale-1by2 {
    @include frame("rect", 50%);
  }

  &.-scale-3by4 {
    @include frame("rect", 133.33%);
  }

  &.-scale-4by3 {
    @include frame("rect", 75%);
  }

  &.-scale-2by3 {
    @include frame("rect", 150%);
  }

  &.-scale-3by2 {
    @include frame("rect", 66.66%);
  }

  &.-contain {
    img {
      object-fit: contain;
    }
  }

  &.-img-free {
    img {
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &.-zoom {
    @include frame("zoom");
  }

  &.-shine {
    @include frame("shine");
  }
}

.img {
  &.-bg {
    @include img("bg");
  }

  &.-fit {
    @include img("fit");
  }

  &.-cover {
    @include img("cover");
  }

  &.-contain {
    @include img("contain");
  }
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;

  &-item {
    margin: 4px 0 8px;
    position: relative;
  }

  &-link {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: inherit;

    &:hover {
      color: $primary;
      text-decoration: none;
    }
  }

  &-toggle {
    display: inline-flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    span {
      display: inline-flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      transition: 0.3s;

      &:hover {
        background-color: rgba(#000, 0.1);
      }
    }

    &.active span {
      transform: rotate(90deg);
    }
  }

  &-sub {
    display: none;
  }

  &-sub &-link {
    padding-left: 40px;
  }

  &-sub-2 &-link {
    padding-left: 60px;
  }

  &-sub-3 &-link {
    padding-left: 80px;
  }

  &-item:hover>&-link {
    background-color: rgba($primary, 0.05);
  }

  @media (min-width: #{$navbar-breakpoint}px) {

    // menu root
    &-root {
      display: flex;
    }

    &-root>&-item {
      margin: 0;

      &:not(:last-child) {
        margin-right: 30px;
      }

      &-mega {
        position: static !important;
      }
    }

    &-root>&-item>&-link {
      padding: 0 !important;
      font-size: 18px;
      position: relative;

      &::after {
        content: '';
        display: block;
        width: 100%;
        border-bottom: 2px solid $primary;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: 0.4s;
        transform: rotateY(90deg);
      }

      &.active {
        color: $primary;

        &::after {
          transform: rotateY(0);
        }
      }
    }

    &-root>&-item:hover>&-link {
      background: 0;
      color: $primary;

      &::after {
        transform: rotateY(0);
      }
    }

    &-root>&-item>&-toggle {
      display: none;
    }

    &-root &-sub {
      min-width: 160px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
      position: absolute;
      z-index: 2;
      top: -4px;
      left: calc(100% + 4px);
      border-radius: 4px;
      transform-origin: 0 4px;
      background-color: $white;

      &::before {
        content: "";
        display: block;
        width: 8px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 100%;
      }

      &-right {
        left: auto;
        right: calc(100% + 4px);
        transform-origin: 100% 0;

        &::before {
          right: auto;
          left: 100%;
        }
      }
    }

    &-root &-sub &-link {
      padding-left: 20px;
    }

    &-root &-item-group>&-link {
      padding-right: 40px;
    }

    &-root &-item-group:hover>&-sub {
      display: block;
      animation: menu-float-show 0.2s ease-out;
    }

    &-root>&-item-group>&-sub {
      transform-origin: 0 0;
      top: calc(100% + 4px);
      left: 0;

      &::before {
        width: 100%;
        height: 8px;
        right: auto;
        left: 0;
        top: auto;
        bottom: 100%;
      }
    }

    &-mega {
      display: none;
      width: 100%;
      padding: 0 0 20px;
      position: absolute;
      z-index: 10;
      top: 100%;
      left: 0;

      &::before {
        content: "";
        display: block;
        width: 100vw;
        height: 100%;
        border-top: 1px solid $border-color;
        background-color: $white;
        position: absolute;
        top: 0;
        left: 50%;
        z-index: -1;
        transform: translateX(-50%);
        @include shadow("xs");
      }
    }

    &-item-mega:hover &-mega {
      display: block;
      animation: menu-fade-in 0.4s;
    }

    &-mega &-sub {
      display: block;
      width: 100%;
      top: auto;
      left: auto;
      right: auto;
      margin: 0;
      position: static;
      background: 0;
      box-shadow: unset;
    }

    &-mega &-link {
      white-space: normal;
    }

    &-mega &-toggle {
      display: none;
    }

    &-mega &-item:hover>&-sub {
      animation: none;
    }

    &-mega &-item:hover &-link {
      background: 0;
    }

    &-mega>&-sub {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }

    &-mega>&-sub>&-item {
      margin: 0;
    }

    &-mega>&-sub>&-item>&-link {
      margin-bottom: 16px;
      line-height: 20px;
      padding: 10px 20px;
      font-weight: 600;
      border-bottom: 1px dashed $border-color;

      &:hover {
        color: $primary;
      }
    }

    &-mega &-sub &-sub &-link {
      line-height: 20px;
      padding: 6px 20px 6px 40px;
      position: relative;
      left: 0;
      transition: 0.3s;

      &::before {
        content: "➙";
        position: absolute;
        top: 6px;
        left: 20px;
      }

      &:hover {
        left: 10px;
        color: $primary;
      }
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) and (max-width: 1300px) {
    &-full-width>&-item:last-child>&-sub {
      left: auto;
      right: 0;
      transform-origin: 100% 0;
    }
  }

  @media (min-width: #{$navbar-breakpoint}px) and (max-width: 1600px) {
    &-full-width>&-item:last-child>&-sub &-sub {
      left: auto;
      right: calc(100% + 4px);
      transform-origin: 100% 4px;
    }
  }
}

.about-nav {
  display: block;
  text-align: center;
  white-space: nowrap;
  overflow: auto;
  padding: 8px 0;
  border-bottom: 1px solid $border-color;
  margin-bottom: 24px;

  .nav-item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 15px;
    }
  }

  .nav-link {
    display: inline-block;
    font-size: 14px;
    line-height: 33px;
    padding: 0 20px;
    border-radius: 4px;
    border: 1px solid $primary;
    color: $primary;

    &:hover {
      color: $primary;
      background-color: $light;
      text-decoration: none;
    }

    &.active {
      background-color: $primary;
      color: $white;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }
  }
}

.about {
  &__title {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px;
  }
}

.contact-form {
  padding: 30px;
  background-color: $light;

  label {
    font-weight: 700;
  }
}

.contact-map {
  height: 400px;
}

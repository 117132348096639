.project {
  position: relative;
  z-index: 1;

  &__frame {
    @include frame('rect', 80%)
  }

  &__overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 10;
    padding: 16px;
    background-color: rgba($primary, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    color: $white;
    opacity: 0;
    transition: 0.4s;

    &:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &:hover &__overlay {
    opacity: 1;
  }

  &__logo {
    margin-bottom: 12px;

    img {
      height: 75px;
    }
  }

  &__title {
    font-size: 20px;
    color: $white;
    text-align: center;
    text-transform: uppercase;
  }

  @media (max-width: 1599px) {
    &__logo {
      img {
        height: 60px;
      }
    }

    &__title {
      font-size: 18px;
    }
  }

  @media (max-width: 575px) {
    &__logo {
      img {
        height: 50px;
      }
    }

    &__title {
      font-size: 13px;
    }

    &__overlay {
      opacity: 1;
    }
  }
}

.project-2 {
  @include shadow('xs');
  overflow: hidden;
  border-radius: 4px;

  &__frame {
    @include frame('rect', 40%);
    @include frame('zoom');
  }

  &__body {
    align-items: center;
    padding: 16px 20px;
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  @media (max-width: 1399px) {
    &__title {
      font-size: 15px;
    }
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 14px;
    }
  }

  @media (max-width: 575px) {
    &__body {
      flex-direction: column;
    }

    &__title {
      text-align: center;
      margin-bottom: 8px;
    }
  }
}

.project-3 {
  &__frame {
    @include frame('rect', 80%);
    @include frame('zoom');
    width: 100px;
    margin-right: 16px;
  }

  &__title {
    @include max-line(2);
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__info {
    color: #999;
  }
}

.news {
  position: relative;
  z-index: 1;

  &__frame {
    @include frame('rect', 85%);
    @include frame('zoom');
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 16px 0 14px;
  }

  &__desc {
    @include max-line(4);
  }

  &__time {
    width: 70px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: $primary;
    color: $white;
  }

  &__date {
    display: flex;
    width: 70px;
    height: 55%;
    justify-content: center;
    align-items: center;
    color: $white;
    font-size: 36px;
    font-weight: 700;
  }

  &__month {
    background-color: $secondary;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
  }
}

.library {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__wrapper {
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }
  }

  &__frame {
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: 0.4s;
    }

    &:hover img {
      transform: scale(1.05);
    }
  }

  &__list {
    height: 0;
    overflow: hidden;
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    font-size: 14px;
    font-weight: 700;
    color: $danger;
    border-bottom: 1px solid $primary;
    padding: 16px 0;
  }
}

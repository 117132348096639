@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$times: TimesNewRoman,
"Times New Roman",
Times,
Baskerville,
Georgia,
serif;
@include font-face('UTMAurora', 'UTMAurora');

$aurora: 'UTMAurora',
sans-serif;

.post {
  &-title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &-info {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #666;
    margin-bottom: 16px;
  }

  &-sapo {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
  }
}

.relate-post {
  border: 1px dashed $green;
  border-radius: 20px;
  padding: 20px;

  &__title {
    font-size: 20px;
    font-weight: 700;
    color: $green;
    padding-bottom: 4px;
    margin-bottom: 16px;

    span {
      display: inline-block;
      padding-bottom: 6px;
      border-bottom: 2px solid $green;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    display: inline-block;
    padding-left: 16px;
    position: relative;
    color: $gray-800;

    &::before {
      content: '\f009';
      font-family: 'fontawesome';
      position: absolute;
      top: 4px;
      left: 0;
      font-size: 8px;
      color: $green;
    }

    &:hover {
      color: $green;
      text-decoration: none;
    }
  }
}

.sharing {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;

  &__label {
    color: #666;
    margin-right: 20px;
  }

  &__list {
    display: flex;
  }

  &__item {
    display: flex;

    &:hover {
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }

    .fa {
      display: flex;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: $white;
      background-color: $facebook;
    }

    .fa-twitter {
      background-color: $twitter;
    }

    .fa-pinterest {
      background-color: $pinterest;
    }

    .fa-envelope-o {
      background-color: $dark;
    }

    .fa-print {
      background-color: $light;
      color: #000;
    }
  }
}

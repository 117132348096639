.logo {
  display: inline-flex;
  margin: 10px 0;

  img {
    height: 50px;
  }
}

.header {
  position: relative;
  z-index: 100;

  &__inner {
    display: flex;
    align-items: center;
    position: relative;
  }

  &__slogan {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-left: auto;
    padding-left: 10px;
  }

  &__elements {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__btn {
    display: inline-flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    position: relative;
    color: $gray-800;
    font-size: 20px;

    &+& {
      margin-left: 4px;
    }

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    span {
      display: inline-flex;
      width: 15px;
      height: 15px;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      border-radius: 50%;
      font-size: 11px;
      font-weight: 700;
      text-align: center;
      color: $white;
      position: absolute;
      top: 0;
      right: 0;
    }

    img {
      max-height: 16px;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }

  @media (min-width: 1200px) {
    &__slogan {
      font-size: 16px;
      margin-left: 0;
    }

    &__logo {
      margin: 12px 0;

      img {
        height: 76px;
      }
    }

    &__btn {
      display: none;
    }
  }
}

.toppage {
  background-color: $primary;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
  }

  &__nav {
    display: flex;
  }

  &__nav-item {
    color: $white;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    span {
      display: none;
    }
  }

  @media (min-width: 1200px) {
    &__nav-item {
      &:not(:last-child) {
        margin-right: 30px;
      }

      span {
        display: inline-block;
      }
    }
  }
}

.social {
  display: flex;

  &__item {
    display: flex;
    width: 26px;
    height: 26px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $white;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:hover {
      color: $primary;
      background-color: $white;
      text-decoration: none;
    }
  }

  @media (min-width: 1200px) {
    margin-left: auto;
    margin-right: 16px;

    &__item {
      background-color: transparent;
      color: $white;
      transition: 0.3s;

      img {
        filter: brightness(0) invert(1);
      }

      &:hover {
        img {
          filter: brightness(0.5) invert(0);
        }
      }
    }
  }
}

.lang {
  display: flex;

  &__item {
    display: flex;

    &:not(:last-child) {
      margin-right: 20px;
    }

    img {
      height: 14px;
    }
  }
}

.search-btn {
  display: none;

  @media (min-width: 1200px) {
    display: inline-flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    background: 0;
    border: 0;
    font-size: 16px;
    margin-right: 16px;
    color: $white;
    padding: 0
  }
}

.search {
  padding: 10px 20px 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid $border-color;

  .input-group-text {
    background-color: $white;
    border-left: 0;
  }

  .form-control {
    border-right: 0;

    &:focus {
      border-color: #ddd;
    }
  }

  @media (min-width: 1200px) {
    display: none;
    width: 370px;
    padding: 0;
    margin: 0;
    border: 0;
    position: absolute;
    top: -25px;
    right: 0;
    transform: translateY(-50%);

    .form-control {
      border: 0;
      padding-left: 16px;
    }

    .input-group-text {
      border: 0;
    }
  }
}

.section {
  padding: 40px 0 50px;

  &__title {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.2;
    margin-bottom: 40px;
  }

  &__desc {
    max-width: 1000px;
    text-align: center;
    margin: -20px auto 40px;
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 30px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 24px;
    }
  }
}

.aside {
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    color: $white;
    background-color: $primary;
    margin-bottom: 15px;
    border-radius: 4px;
    position: relative;
    padding: 7px 7px 7px 55px;

    &::before {
      content: '';
      display: block;
      width: 40px;
      height: 35px;
      border-radius: 4px 0 0 4px;
      background-color: rgba(#000, 0.3);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.footer {
  padding: 50px 0 30px;
  background: url('../images/footer-bg.png') no-repeat;
  background-position: center bottom;
  background-size: 100% auto;
  background-color: #145EA8;

  &__title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 12px;
  }

  &__name {
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
    margin-bottom: 16px;
  }

  &__contact {

    &,
    a,
    a:hover {
      color: $white;
    }
  }

  &__copyright {
    padding: 20px 0;
    text-align: center;
    position: relative;
    color: $white;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background: linear-gradient(to right, transparent, #fff, transparent);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.f-contact {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    padding-left: 30px;
    position: relative;

    &,
    a,
    a:hover {
      color: $white;
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    .fa {
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
}

.f-menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__link {
    display: inline-block;
    color: $white;
    padding-left: 20px;
    position: relative;
    left: 0;
    transition: 0.3s;

    &:hover {
      color: $white;
      text-decoration: none;
      left: 5px;
    }

    &::before {
      content: '\f105';
      font-family: fontawesome;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.f-social {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    color: $white;
    font-size: 18px;

    &:hover {
      color: $white;
      text-decoration: none;
    }

    .fa {
      display: flex;
      width: 35px;
      height: 35px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $white;
      color: $primary;
      margin-right: 20px;
    }
  }
}

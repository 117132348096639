.home-about {
  padding: 50px 0 20px;

  &__inner {
    background: url('../images/home-about-bg.png') center/contain no-repeat;
  }

  &__title {
    font-size: 36px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  &__desc {
    margin-bottom: 20px;
  }

  @media (max-width: 991px) {
    &__title {
      font-size: 30px;
    }

    &__subtitle {
      font-size: 18px;
    }
  }

  @media (max-width: 767px) {
    &__title {
      font-size: 24px;
    }

    &__subtitle {
      font-size: 16px;
    }
  }
}

.pros {
  position: relative;
  z-index: 1;
  background-color: $primary;
  overflow: hidden;
  padding: 30px 0 0;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;

    img {
      height: 65px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    color: $white;
    text-align: center;
    margin-bottom: 16px;
  }

  &__desc {
    color: $white;
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
  }

  &__bg-1,
  &__bg-2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  &__bg-2 {
    top: auto;
    left: auto;
    right: 0;
    bottom: 0;
    transform: rotateY(180deg);
  }

  @media (max-width: 575px) {
    &__title {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
}

.banner-slider {
  position: relative;
  z-index: 1;

  &__slide {
    position: relative;
  }

  &__frame {
    @include frame("rect", 56.25%);
    max-height: calc(100vh - 150px);

    img {
      object-position: 100% 50% !important;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
  }

  &__title {
    font-size: 50px;
    font-family: $aurora;
    color: $white;
    line-height: 1.2;
    margin-bottom: 20px;

    span {
      position: relative;
    }
  }

  &__desc {
    font-size: 16px;
    color: $white;
    max-width: 50%;

    a,
    a:hover {
      color: $white;
    }
  }

  &__pagination {
    position: absolute;
    bottom: 30px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      margin: 0 10px;
      opacity: 1;
      background: $white;

      &:focus {
        outline: 0;
      }

      &-active {
        background: $primary;
        background-clip: content-box;
        border: 1px solid $primary;
        padding: 3px;
      }
    }
  }

  @media (max-width: 1399px) {
    &__title {
      font-size: 30px;
    }

    &__desc {
      font-size: 14px;
    }
  }

  @media (max-width: 991px) {
    &__desc {
      max-width: 100%;
    }
  }

  @media (max-width: 767px) {
    &__frame {
      max-height: calc(100vh - 120px);
    }

    &__title {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__desc {
      font-size: 12px;

      p:not(:last-child) {
        margin-bottom: 4px;
      }
    }

    &__pagination {
      bottom: 4px;

      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 0 4px;
      }
    }
  }

  @media (max-width: 575px) {
    &__frame {
      @include frame("rect", 66.66%);
    }
  }
}

.gallery-slider {
  position: relative;
  z-index: 1;

  &__frame {
    @include frame("rect", 60%);
  }

  &__pagination {
    position: absolute;
    bottom: 30px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);

    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      margin: 0 10px;
      opacity: 1;
      background: $white;

      &:focus {
        outline: 0;
      }

      &-active {
        background: $primary;
        background-clip: content-box;
        border: 1px solid $primary;
        padding: 3px;
      }
    }
  }

  @media (max-width: 767px) {
    &__pagination {
      bottom: 4px;

      .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        margin: 0 4px;
      }
    }
  }
}

.partner-slider {
  position: relative;
  z-index: 1;

  &__frame {
    display: block;
    border: 1px solid $border-color;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-bottom: 60%;
    }

    img {
      width: 90%;
      height: 90%;
      object-fit: contain;
      object-position: 50% 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__prev,
  &__next {
    display: flex;
    width: 40px;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: $primary;
    position: absolute;
    top: 0;
    left: -40px;
    z-index: 10;
    cursor: pointer;

    &:focus {
      outline: 0;
    }
  }

  &__next {
    left: auto;
    right: -40px;
  }

  @media (max-width: 575px) {
    padding: 0 30px;

    &__prev {
      left: -10px;
    }

    &__next {
      right: -10px;
    }
  }
}

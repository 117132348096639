.news-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  &__col {
    &:nth-child(2) {
      grid-column: 1 / span 1;
      grid-row: 2 / span 1;
    }

    &:nth-child(3) {
      grid-column: 2 / span 1;
      grid-row: 2 / span 1;
    }

    &:nth-child(4) {
      grid-column: 2 / span 1;
      grid-row: 1 / span 1;
    }

    &:nth-child(5) {
      grid-column: 3 / span 1;
      grid-row: 1 / span 1;
    }

    &:nth-child(6) {
      grid-column: 3 / span 1;
      grid-row: 2 / span 1;
    }
  }

  &__frame {
    @include frame('rect', 66.66%);
    @include frame('zoom');
    height: 100%;
  }

  &__body {
    background-color: $primary;
    padding: 24px;
    position: relative;
    z-index: 10;

    &::before {
      content: '';
      display: block;
      border: 20px solid transparent;
      border-bottom-color: $primary;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }
  }

  &__col:nth-child(4)::before {
    bottom: auto;
    top: 100%;
    border-color: $primary transparent transparent;
  }

  &__title {
    font-size: 14px;
    font-weight: 700;
    color: $white;
    margin-bottom: 20px;

    a,
    a:hover {
      color: $white;
      text-decoration: none;
    }
  }

  &__info {
    margin-bottom: 20px;
    color: $white;
  }

  &__desc {
    @include max-line(4);
    color: $white;
  }

  @media (max-width: 1199px) {
    &__body {
      padding: 20px;
    }

    &__title {
      margin-bottom: 8px;
    }

    &__info {
      margin-bottom: 8px;
    }
  }

  @media (max-width: 991px) {
    &__desc {
      @include max-line(2);
    }
  }

  @media (max-width: 767px) {
    display: block;

    &__body {
      &:not(:last-child) {
        margin-bottom: 30px;
      }

      &::before {
        top: auto !important;
        bottom: 100% !important;
        border-color: transparent transparent $primary !important;
      }
    }
  }
}


.as-menu {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 4px;
  border: 1px solid $border-color;
  overflow: hidden;

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }

    &:nth-child(odd) {
      background-color: $light;
    }
  }

  &__link {
    display: block;
    padding: 14px 15px 14px 30px;
    position: relative;
    color: #333;

    &:hover {
      color: $primary;
      text-decoration: none;
    }

    &::before {
      content: '\f105';
      font-family: fontawesome;
      color: $primary;
      position: absolute;
      top: 14px;
      left: 14px;
    }
  }
}

.project-detail {
  &__title {
    border-bottom: 1px solid $border-color;
    margin-bottom: 30px;
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
  }

  &__content {
    table {
      td {
        vertical-align: middle;

        &:first-child {
          font-weight: 700;
          white-space: nowrap;
        }
      }
    }
  }
}

.documents {
  background-color: $light;
  text-align: center;

  td,
  th {
    padding: 8px 20px;
    vertical-align: middle;
  }

  thead {
    th {
      background-color: $primary;
      color: $white;
      border: 0;

      &:not(:last-child) {
        border-right: 1px solid $white;
      }

      &:not(:first-child) {
        border-left: 1px solid $white;
      }
    }
  }

  @media (max-width: 575px) {

    th,
    td {
      padding: 6px 8px;
      font-size: 13px;
    }
  }
}
